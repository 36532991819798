.container{
  display: flex;
    justify-content: center;
}
.flex{
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;

}
.mapa{
  width: 395px;
  height: 340px;
}
.formLabel{
color: #2b4a3b;
font-size: 22px;
font-weight: 400;
}
.contactContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0% 10% 0% 5%;
}
.title1{
  font-size: 26px;
line-height: 30px;
text-align: right;
color: #D9D9D9;
}
.formik{
  padding-bottom: 5%;
}
.title12{
  font-size: 22px;
  line-height: 30px;
  text-align: right;
  color: #fff;
  }
.formLabelContact{
  .formLabel{
    color: #000;
    font-size: 28px;
    font-weight: 400;
    }
}
.mapContainer{
  display: flex;
  flex-direction: row;
  width: 89%;
  height: auto;
  margin: 6%;
  box-shadow: 6px 9px 45px 8px rgb(108, 110, 113);
}
.mapText{
  padding: 5%;
  width: 100%;

}
.contactText{
  padding: 10% 15% 15% 15%;
}
.redLetters{
  font-weight: 400;
font-size: 20px;
line-height: 23px;
margin-bottom: 5%;
color: #A31918;
}
.titleContainer {
  width: 100%;
  display: flex;
}
.greyLetters{
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #717171;
}
.formDiv{
  display: flex;
  margin-bottom: 2%;
    flex-direction: column;
    align-content: flex-start;
}
.contenedorFormik{
  margin-right: 5%;
}
.column{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 5%;
}
.formField{
  width: 100%;
  height: 54px;
  padding-left: 2%;
}
.formFieldContact{
  width: 401.13px;
height: 31.4px;;
  padding-left: 2%;
}
.formMessageContact{
  width:401.13px;
}
@media (max-width: 900px) {
  .formFieldContact{
    width: 389.13px;
  height: 41.4px;;
    padding-left: 2%;
  }
  .formMessageContact{
    width: 389.13px;
  }
}
.rowMap {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.width{
  width: 100%;
}
.map {
 width: 100%;
 height: 100%;
}
.formMessage{
  width: 638px;
height: 128px;
}
.container {
 /*  padding: 50px 0px 20px 50px;
  margin-left: 5%; */
  width: 100%;
}
.containerTitle {
  padding: 50px 0px 20px 50px;
  margin-left: 10%;
  width: 44.5% !important;
}
.container2 {
  padding: 50px 0px 20px 50px;
  margin-left: 5%;
  width: 35%;
}
.formFlex{
  
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

}
.container22{
  width: 400px;
  display: none;
  background-color: #A31918;
  margin-top: 20% !important;
  border: 0.5px solid #A31918;
}
.title {
  font-family: Franklin Gothic Medium;
  font-size: 35px !important;
  font-weight: 450 !important;
  line-height: 51px;
  letter-spacing: 0em;

  color: #A31918;
  text-align-last: left;
}
.contactTextWidth{
  width: 50%;
}
.titleWhite{
  font-family: Franklin Gothic Medium;
  font-size: 50px !important;
  font-weight: 450 !important;
  line-height: 51px;
  letter-spacing: 0em;

  color: #9D9D9D;
  text-align-last: left;
}
.title2 {
  font-family: Franklin Gothic Heavy;
  font-size: 40px !important;
  font-weight: 400 !important;
  line-height: 57px;
  letter-spacing: 0em;
  text-align: center !important;
  color: #A31918;
}
.formikWidth{
  padding: 0px 0px 0px 0px;

}
@media (max-width: 800px){
  .formMessage{
    width: 400px;
  height: 128px;
  }
  .mapContainer{
    display: flex;
    flex-direction: column-reverse;
    width: 89%;
    height: auto;
    margin: 6%;
    box-shadow: 6px 9px 45px 8px rgb(108, 110, 113);
  }
  .formikWidth{
    padding: 0px 0px 0px 0px;

  }
  .container2 {
    width: 400px;
    background-color: #A31918;
    margin-top: 0% !important;
    border: 0.5px solid #A31918;
  }
  .formFieldContact{
    width: 289.13px;
  height: 41.4px;;
    padding-left: 2%;
  }
  .formMessageContact{
    width: 289.13px;
  }
  .containerred{
    width: 400px;
    display: block;
    margin-bottom: 15%;

    background-color: #fff;
    margin-top: 0% !important;
    border: 0.5px solid #fff;
}
.container22{
  width: 400px;
  display: block;
  background-color: #A31918;
  margin-top: 0% !important;
  border: 0.5px solid #A31918;
}
.contactContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0% 0% 0% 0%;
}
  .container {
    padding: 50px 0px 20px 0px;
    width: 100%;
  }
  .titleContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 600px){
  .formMessage{
    width: 300px;
  height: 128px;
  }
}
.ErrorMessage{
  color: #A31918;
  font-weight: 600;
  font-size: 12px;
}
.button{
  width: 20%;
  background: #A31918;
box-shadow: 4px 3px 5px rgba(0, 0, 0, 0.6);
border-radius: 17px;
  color: #FFFFFF;
  height:7%;
}
.buttonContainer{
  display: flex;
    justify-content: flex-end;
    height: 100%;
}

.buttonCU{
  width: 30%;
  background: #A31918;
box-shadow: 4px 3px 5px rgba(0, 0, 0, 0.6);
border-radius: 17px;
  color: #FFFFFF;
  height:7%;
}
.buttonCU:hover{
  width: 30%;
  background: #ba1111;
box-shadow: 4px 3px 5px rgba(0, 0, 0, 0.6);
border-radius: 17px;
  color: #FFFFFF;
  height:7%;
}
.buttonContainerCU{
 
  display: flex;
  margin-top: 5%;
  justify-content: center;
  height: 100%;
}
.button:focus{
  background: #bc4949;
}
.container2 {
  width: 400px;
  background-color: #A31918;
  margin-top: 20%;
  border: 0.5px solid #A31918;
}
.containerred {
  width: 400px;
  display: none;
  background-color: #fff;
  margin-top: 20%;
  border: 0.5px solid #fff;
}
@media (max-width: 750px) {
  .contactContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .contactTextWidth{
    width: 100%;
  }
  .formikWidth{
    padding: 0px 0px 0px 0px;
    width: 100%;
  }
  .mapa{
  margin-top: 15%;
    width: 300px;
  height: 200px;
  }
  .flex{
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
  
  }
  .containerTitle {
    padding: 50px 42px 20px 60px;
    margin-left: 0%;
    width: 100% !important;
  }
  .containerred {
    width: 400px;
    background-color: #fff;
    margin-top: 0%;
    display: block;
    margin-bottom: 15%;
    border: 0.5px solid #fff;
    margin-bottom: 10%;
  }
  .buttonContainerCU{
    width: 350.13px;
    display: flex;
    margin-top: 5%;
    justify-content: center;
    height: 100%;
  }
  
  .formMessageContact{
    width: 359.13px;
  }
  
  .title1{
    font-size: 26px;
  line-height: 30px;
  text-align: justify;
  color: #D9D9D9;
  }
  .title12{
    font-size: 22px;
    line-height: 30px;
    text-align: justify;
    color: #fff;
    }
    .formFieldContact{
      width: 359.13px;
    height: 41.4px;;
  
      padding-left: 2%;
    }
}
@media (max-width: 600px) {
 
  
  .containerred {
    width: 320px !important;
    background-color: #fff;
    display: block;
    margin-top: 0%;
    border: 0.5px solid #fff;
    margin-bottom: 15%;

  }
  
  .container22{
    width: 320px;
    display: block;
    background-color: #A31918;
    margin-top: 0% !important;
    margin-bottom: 15%;
    border: 0.5px solid #A31918;
  }
  .mapContainer{
    display: flex;
    flex-direction: column-reverse;
    width: 80%;
    height: auto;
    margin: 10%;
    box-shadow: 6px 9px 45px 8px rgb(108, 110, 113);
  }
}